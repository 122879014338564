// Track Page View
import { generateEventId, parseCurrencyToNumber } from './default';
import { request } from './api';

// Track Add to Cart
export function addToCart(productID, quantity) {
	const event = 'AddToCart';
	const eventID = generateEventId(event);
	fbq('trackSingle', window.localData.converstionPixelId, event, {
		content_ids: [productID],
		content_type: 'product',
		quantity,
		eventID,
	});

	return eventID;
}

// Track View Content
export function viewContent(productID, value) {
	const event = 'ViewContent';
	const eventID = generateEventId(event);
	const sendContenID = async () => {
		await request(`${window.location.origin}/api/v1/fb-pixel-id`, {
			headers: { 'Content-Type': 'application/json' },
			method: 'POST',
			body: JSON.stringify({
				event_id: eventID,
				event,
				data: {
					product_ids: [productID],
					total_price: value,
				},
			}),
		});
	};

	sendContenID();
	fbq('trackSingle', window.localData.converstionPixelId, event, {
		content_ids: [productID],
		content_type: 'product',
		eventID,
	});

	return eventID;
}

// Track Initiate Checkout
export function initiateCheckout(cartItems) {
	const event = 'InitiateCheckout';
	const eventID = generateEventId(event);
	const idArray = [];
	const parsedArray = cartItems.filter((item) => {
		const doesIncludes = idArray.includes(item?.id);
		if (!doesIncludes) {
			idArray.push(item?.id);
		}
		return !doesIncludes;
	});

	const contents = parsedArray.map((item) => ({
		content_id: item.id,
		quantity: item.quantity,
	}));

	const sendInitiateCheckoutID = async () => {
		const valueText = document.querySelector('.cart-summary__footer-col.text-right');
		const valueNumber = parseCurrencyToNumber('sl-SI', 'EUR', valueText.textContent);
		await request(`${window.location.origin}/api/v1/fb-pixel-id`, {
			headers: { 'Content-Type': 'application/json' },
			method: 'POST',
			body: JSON.stringify({
				event_id: eventID,
				event,
				data: {
					product_ids: contents.map((item) => item.content_id),
					total_price: valueNumber,
				},
			}),
		});
	};

	sendInitiateCheckoutID();
	fbq('trackSingle', window.localData.converstionPixelId, event, {
		contents,
		num_items: cartItems.length,
		eventID,
	});

	return eventID;
}

// Track Purchase
export function purchase(transactionID, currency, totalValue, items) {
	const event = 'Purchase';
	const eventID = generateEventId(event);
	fbq('trackSingle', window.localData.converstionPixelId, event, {
		transaction_id: transactionID,
		currency,
		value: totalValue,
		contents: items.map((item) => ({
			content_id: item.item_id,
			quantity: item.quantity,
			price: item.price,
		})),
		eventID,
	});

	return eventID;
}

window.viewContent = viewContent;
window.initiateCheckout = initiateCheckout;
